import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function usePaymentAccountsList() {

    //User toast
    const toast = useToast()

    const refPaymentAccountListTable = ref(null)

    const tableColumns = [
        {key: 'description', label:'Account Nickname', sortable: true},
        {key: 'bank_name_en', sortable: true},
        {key: 'bank_name_cn', sortable: true},
        {key: 'bank_account', sortable: true},
        {key: 'action'}
    ]

    const perPage = ref(10)
    const totalPaymentAccounts = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refPaymentAccountListTable.value ? refPaymentAccountListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalPaymentAccounts.value,
        }
    })

    const refetchData = () => {
        refPaymentAccountListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })

    const fetchPaymentAccounts = (ctx, callback) => {
        store.dispatch('invoice/fetchPaymentAccounts',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response =>{
            const {paymentaccounts, total} = response.data
            callback(paymentaccounts)
            totalPaymentAccounts.value = total
        
        })
        .catch((error) => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching payment accounts list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchPaymentAccounts,
        tableColumns,
        perPage,
        currentPage,
        totalPaymentAccounts,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPaymentAccountListTable,
        ability,
        refetchData
    }

}